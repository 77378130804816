import store from '@/store'

export const contentTableConfig = {
  // title: '('param.app-application-list')',
  propList: [
    {
      prop: 'name',
      label: 'general.name',
      width: 180
    },
    {
      prop: 'areaPoint',
      label: 'geoFence.area-point'
    },
    {
      prop: 'tenantId',
      label: 'user.tenant',
      width: 180,
      formatter: (row) => {
        return row ? store.getters.tenantDataMap[row] : ''
      }
    },
    {
      prop: 'description',
      label: 'general.description',
      width: 200
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 200
      // slotName: 'formateTime'
    },
    {
      label: 'common.app-operation',
      width: '280',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
